<!-- <template>
    <v-form>
        <v-dialog
            v-model="createCompanyDialog"
            persistent
            max-width="600px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                    New Company
                </v-btn>
            </template>
            <v-card>
                <v-card-title>
                    <span class="headline">New Company</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-text-field
                            label="Contract"
                            v-model="form.contract"
                            required
                            :rules="[v => !!v || 'Contract Number is required.']"
                        ></v-text-field>
                        <v-text-field
                            label="Name"
                            v-model="form.name"
                        ></v-text-field>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="createCompanyDialog = false"
                    >
                        Close
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="createCompany"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template> -->

<template>
  <Form :title="title" :data="{}" :create="true" @createdCompany="createCompany"></Form>
</template>

<script>
import Form from './Form'

export default {
  components: {
    Form
  },

  data () {
    return {
      title: 'New Company',
      myData: 'my|your Data',
      createCompanyDialog: false,
      form: {
        contract: '',
        name: ''
      }
    }
  },

  methods: {
    createCompany (data) {
      this.$axios.post('api/companies', data).then(response => {
        this.fetchCompanies()
        this.createCompanyDialog = false
        this.$emit('companyCreated', response.data)
        this.showDialog = false
      }).catch(
        result => {
          this.$emit('companyCreatedError')
        }
      )
    },
    fetchCompanies () {
      this.$axios.get('api/companies').then(response => {
        this.companies = response.data
      })
    }
  }
}
</script>
